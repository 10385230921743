import React from "react";
import { Link } from "prismic-reactjs";

import ComingSoon from "./ComingSoon";

export default class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      doc: null,
      ComingSoon: false,
      muted: true
    };
    if (props.prismicCtx) {
      this.fetchPage(props);
    }
  }

  componentDidUpdate(prevProps) {
    this.props.prismicCtx.toolbar();
    if (!prevProps.prismicCtx) {
      this.fetchPage(this.props);
    }
  }

  fetchPage(props) {
    if (props.prismicCtx) {
      return props.prismicCtx.api.getSingle("home", {}, (err, doc) => {
        if (doc) {
          this.setState({ doc });
        } else {
          this.setState({ ComingSoon: !doc });
        }
      });
    }
    return null;
  }

  render() {
    if (this.state.doc) {
      return (
        <div data-wio-id={this.state.doc.id} className="home">
          <div className="video-overlay">
            <div className="video-caption">
              <h1>{this.state.doc.data.title[0].text}</h1>
              <p>{this.state.doc.data.description[0].text}</p>
            </div>
            <div className="insta-wrapper">
              <div id="insta" className="instagram">
                <span className="descriptions">
                  <a
                    href="https://www.instagram.com/bodegaband/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span className="insta-button">
                      <p>Follow us on Instagram</p>
                      <img
                        src="../images/insta-button.svg"
                        alt="Social Instagram"
                      />
                    </span>
                  </a>
                </span>
              </div>
            </div>
          </div>
          <video
            width="100%"
            autoPlay={true}
            playsInline={true}
            loop
            muted={this.state.muted}
            preload="auto"
          >
            <source
              src={Link.url(
                this.state.doc.data.video,
                this.props.prismicCtx.linkResolver
              )}
              type="video/mp4"
            />
          </video>

          <img
            className="sound-img"
            src={
              this.state.muted
                ? "../images/sound-on.svg"
                : "../images/sound-off.svg"
            }
            alt="Sound on"
            onClick={() => this.setState({ muted: !this.state.muted })}
          />
        </div>
      );
    } else if (this.state.ComingSoon) {
      return <ComingSoon />;
    }
    return <h1>Loading</h1>;
  }
}
