import React from "react";
import ComingSoon from "./ComingSoon";

export default class Gallery extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      doc: null,
      ComingSoon: false,
      fullScreenUrl: null,
      currentFullScreenIndex: null
    };
    if (props.prismicCtx) {
      this.fetchPage(props);
    }
  }

  componentDidUpdate(prevProps) {
    this.props.prismicCtx.toolbar();
    if (!prevProps.prismicCtx) {
      this.fetchPage(this.props);
    }
  }

  fetchPage(props) {
    if (props.prismicCtx) {
      return props.prismicCtx.api.getSingle("gallery", {}, (err, doc) => {
        if (doc) {
          this.setState({ doc });
        } else {
          this.setState({ ComingSoon: !doc });
        }
      });
    }
    return null;
  }

  render() {
    if (this.state.doc) {
      const openFullScreen = (image, index) => {
        this.setState({ fullScreenUrl: image });
        this.setState({ currentFullScreenIndex: index });
      };
      const nextFullScreen = () => {
        if (
          this.state.currentFullScreenIndex <
          this.state.doc.data.images.length - 1
        ) {
          const responsiveImage = this.state.doc.data.images[
            this.state.currentFullScreenIndex + 1
          ].image.bigger
            ? this.state.doc.data.images[this.state.currentFullScreenIndex + 1]
                .image.bigger.url
            : this.state.doc.data.images[this.state.currentFullScreenIndex + 1]
                .image.url;
          this.setState({
            fullScreenUrl: responsiveImage,
            currentFullScreenIndex: this.state.currentFullScreenIndex + 1
          });
        }
      };
      const prevFullScreen = () => {
        if (this.state.currentFullScreenIndex > 0) {
          const responsiveImage = this.state.doc.data.images[
            this.state.currentFullScreenIndex - 1
          ].image.bigger
            ? this.state.doc.data.images[this.state.currentFullScreenIndex - 1]
                .image.bigger.url
            : this.state.doc.data.images[this.state.currentFullScreenIndex - 1]
                .image.url;
          this.setState({
            fullScreenUrl: responsiveImage,
            currentFullScreenIndex: this.state.currentFullScreenIndex - 1
          });
        }
      };
      const images = this.state.doc.data.images.map(function(img, index) {
        const responsiveImg = img.image.bigger
          ? img.image.bigger.url
          : img.image.url;
        return (
          <>
            <div
              className="gallery__image"
              key={index}
              style={{ backgroundImage: `url(${responsiveImg})` }}
              onClick={() => openFullScreen(responsiveImg, index)}
            ></div>
          </>
        );
      });

      return (
        <>
          <div data-wio-id={this.state.doc.id} className="gallery">
            <div className="container-overlay"></div>
            <div className="gallery__container">
              <h1>GALLERY</h1>
              <div className="gallery__container__images">{images}</div>
            </div>
            {this.state.fullScreenUrl && (
              <div className="gallery__full-screen">
                <div
                  onClick={() =>
                    this.setState({
                      fullScreenUrl: null,
                      currentFullScreenIndex: null
                    })
                  }
                  className="gallery__full-screen-close"
                ></div>

                <div
                  className="gallery__full-screen__content"
                  style={{
                    backgroundImage: `url(${this.state.fullScreenUrl})`
                  }}
                >
                  <div
                    onClick={() => prevFullScreen()}
                    className="gallery__full-screen-prev"
                  >
                    &lt;
                  </div>
                  <div
                    onClick={() => nextFullScreen()}
                    className="gallery__full-screen-next"
                  >
                    &gt;
                  </div>
                </div>
              </div>
            )}
          </div>
        </>
      );
    } else if (this.state.ComingSoon) {
      return <ComingSoon />;
    }
    return <h1>Loading</h1>;
  }
}
