import React from "react";

export default class Footer extends React.Component {
  render() {
    return (
      <div className="footer">
        <p>
          Copyright © 2022 <span>Bodega Band</span> | All rights reserved
        </p>
      </div>
    );
  }
}
