import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import Header from "./Header";
import Preview from "./Preview";
import ComingSoon from "./ComingSoon";
import Home from "./Home";
import About from "./About";
import Contact from "./Contact";
import Gallery from "./Gallery";
import Videos from "./Videos";
import Shows from "./Shows";
import Footer from "./Footer";

const App = props => (
  <Router>
    <>
      <Header />
      <Switch>
        <Route
          exact
          path="/"
          render={routeProps => (
            <Home {...routeProps} prismicCtx={props.prismicCtx} />
          )}
        />
        <Route
          exact
          path="/about"
          render={routeProps => (
            <About {...routeProps} prismicCtx={props.prismicCtx} />
          )}
        />
        <Route
          exact
          path="/gallery"
          render={routeProps => (
            <Gallery {...routeProps} prismicCtx={props.prismicCtx} />
          )}
        />
        <Route
          exact
          path="/shows"
          render={routeProps => (
            <Shows {...routeProps} prismicCtx={props.prismicCtx} />
          )}
        />
        <Route
          exact
          path="/videos"
          render={routeProps => (
            <Videos {...routeProps} prismicCtx={props.prismicCtx} />
          )}
        />
        <Route
          exact
          path="/contact"
          render={routeProps => (
            <Contact {...routeProps} prismicCtx={props.prismicCtx} />
          )}
        />
        <Route
          exact
          path="/preview"
          render={routeProps => (
            <Preview {...routeProps} prismicCtx={props.prismicCtx} />
          )}
        />
        <Route component={ComingSoon} />
      </Switch>
      <Footer />
    </>
  </Router>
);

export default App;
