import React from "react";

export default function ComingSoon() {
  return (
    <>
      <div className="coming-soon">
        <div>
          <h1>Do not leave the dancefloor!</h1>
          <p>We are currently creating awesome content for you.</p>
          <p>It will be ready sooner than you think!</p>
        </div>
      </div>
    </>
  );
}
