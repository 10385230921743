import React from "react";
import { NavLink } from "react-router-dom";

export default class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showMenu: false
    };
  }

  render() {
    return (
      <>
        <div className="mobile-header">
          <div className="header header-mobile">
            <ul className="social">
              <li>
                <a
                  href="https://www.instagram.com/bodegaband/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src="../images/instagram.png" alt="Social Instagram" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.facebook.com/sanfranciscocoverband"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src="../images/facebook.png" alt="Social Facebook" />
                </a>
              </li>
              <li>
                <a
                  href="https://twitter.com/sfcoverband"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src="../images/twitter.png" alt="Social Twitter" />
                </a>
              </li>
            </ul>
            <div
              className={this.state.showMenu ? "bar-menu opened" : "bar-menu"}
              onClick={() => this.setState({ showMenu: !this.state.showMenu })}
            >
              <div className="bar1" />
              <div className="bar2" />
              <div className="bar3" />
            </div>
          </div>
          {this.state.showMenu && (
            <div className="header-container">
              <ul className="menu">
                <li>
                  <NavLink
                    exact
                    to="/"
                    activeStyle={{ opacity: 0.5 }}
                    onClick={() => this.setState({ showMenu: false })}
                  >
                    Home
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    exact
                    to="/about"
                    activeStyle={{ opacity: 0.5 }}
                    onClick={() => this.setState({ showMenu: false })}
                  >
                    About
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    exact
                    to="/shows"
                    activeStyle={{ opacity: 0.5 }}
                    onClick={() => this.setState({ showMenu: false })}
                  >
                    Shows
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    exact
                    to="/gallery"
                    activeStyle={{ opacity: 0.5 }}
                    onClick={() => this.setState({ showMenu: false })}
                  >
                    Gallery
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    exact
                    to="/videos"
                    activeStyle={{ opacity: 0.5 }}
                    onClick={() => this.setState({ showMenu: false })}
                  >
                    Videos
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    exact
                    to="/contact"
                    activeStyle={{ opacity: 0.5 }}
                    onClick={() => this.setState({ showMenu: false })}
                  >
                    Contact
                  </NavLink>
                </li>
              </ul>
            </div>
          )}
        </div>
        <div className="header header-desktop">
          <ul className="menu">
            <li>
              <NavLink exact to="/" activeStyle={{ opacity: 0.5 }}>
                Home
              </NavLink>
            </li>
            <li>
              <NavLink exact to="/about" activeStyle={{ opacity: 0.5 }}>
                About
              </NavLink>
            </li>
            <li>
              <NavLink exact to="/shows" activeStyle={{ opacity: 0.5 }}>
                Shows
              </NavLink>
            </li>
            <li>
              <NavLink exact to="/gallery" activeStyle={{ opacity: 0.5 }}>
                Gallery
              </NavLink>
            </li>
            <li>
              <NavLink exact to="/videos" activeStyle={{ opacity: 0.5 }}>
                Videos
              </NavLink>
            </li>
            <li>
              <NavLink exact to="/contact" activeStyle={{ opacity: 0.5 }}>
                Contact
              </NavLink>
            </li>
          </ul>
          <ul className="social">
            <li>
              <a
                href="https://www.instagram.com/bodegaband/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="../images/instagram.png" alt="Social Instagram" />
              </a>
            </li>
            <li>
              <a
                href="https://www.facebook.com/sanfranciscocoverband"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="../images/facebook.png" alt="Social Facebook" />
              </a>
            </li>
            <li>
              <a
                href="https://twitter.com/sfcoverband"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="../images/twitter.png" alt="Social Twitter" />
              </a>
            </li>
          </ul>
        </div>
      </>
    );
  }
}
