import React from "react";
import ComingSoon from "./ComingSoon";

export default class About extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      doc: null,
      ComingSoon: false
    };
    if (props.prismicCtx) {
      this.fetchPage(props);
    }
  }

  componentDidUpdate(prevProps) {
    this.props.prismicCtx.toolbar();
    if (!prevProps.prismicCtx) {
      this.fetchPage(this.props);
    }
  }

  fetchPage(props) {
    if (props.prismicCtx) {
      return props.prismicCtx.api.getSingle("about", {}, (err, doc) => {
        if (doc) {
          this.setState({ doc });
        } else {
          this.setState({ ComingSoon: !doc });
        }
      });
    }
    return null;
  }

  render() {
    if (this.state.doc) {
      const members = this.state.doc.data.members.map(function(member, index) {
        return (
          <div key={index} className="single-member">
            {index & 1 ? (
              <div className="odd">
                <img alt="cover" src={member.image.url} />
                <div className="description">
                  <h2>{member.name[0].text}</h2>
                  <p>{member.description[0].text}</p>
                </div>
              </div>
            ) : (
              <div className="even">
                <div className="description">
                  <h2>{member.name[0].text}</h2>
                  <p>{member.description[0].text}</p>
                </div>
                <img alt="cover" src={member.image.url} />
              </div>
            )}
          </div>
        );
      });

      return (
        <>
          <div data-wio-id={this.state.doc.id} className="about">
            <div
              className="hero-image"
              style={{
                backgroundImage: `url(${this.state.doc.data.hero_image.url})`
              }}
            >
              <div className="social">
                <p>Hang out with us</p>
                <div className="icons">
                  <a
                    href="https://www.instagram.com/bodegaband/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src="../images/instagram-full.svg"
                      alt="Social Instagram"
                    />
                  </a>
                  <a
                    href="https://www.facebook.com/sanfranciscocoverband"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src="../images/facebook-full.svg"
                      alt="Social Facebook"
                    />
                  </a>
                  <a
                    href="https://twitter.com/sfcoverband"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src="../images/twitter-full.svg"
                      alt="Social Twitter"
                    />
                  </a>
                </div>
              </div>
            </div>
            <div className="members">{members}</div>
          </div>
        </>
      );
    } else if (this.state.ComingSoon) {
      return <ComingSoon />;
    }
    return <h1>Loading</h1>;
  }
}
