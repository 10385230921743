import React from "react";
import ComingSoon from "./ComingSoon";
import Instafeed from "instafeed.js";

const MONTHS = [
  "JAN",
  "FEB",
  "MAR",
  "APR",
  "MAY",
  "JUN",
  "JUL",
  "AUG",
  "SEP",
  "OCT",
  "NOV",
  "DEC"
];

export default class Shows extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      doc: null,
      ComingSoon: false
    };
    if (props.prismicCtx) {
      this.fetchPage(props);
    }
  }

  componentDidUpdate(prevProps) {
    this.props.prismicCtx.toolbar();
    if (!prevProps.prismicCtx) {
      this.fetchPage(this.props);
    }
  }

  fetchPage(props) {
    if (props.prismicCtx) {
      return props.prismicCtx.api.getSingle("shows", {}, (err, doc) => {
        if (doc) {
          this.setState({ doc });
        } else {
          this.setState({ ComingSoon: !doc });
        }
      });
    }
    return null;
  }

  render() {
    var galleryFeed = new Instafeed({
      get: "user",
      userId: 13464217110,
      accessToken: "13464217110.1677ed0.ecf5cec4850346ff81af9a8f11dbfe7e",
      resolution: "thumbnail",
      limit: 9,
      template:
        '<div><a href="{{link}}" target="_blank" rel="noopener noreferrer"><img src="{{image}}" /></a></div>',
      target: "insta"
    });
    galleryFeed.run();
    if (this.state.doc) {
      const shows = this.state.doc.data.show.map(function(show, index) {
        const parsedMonth = parseInt(show.date.split("-")[1]);
        const parsedDay = parseInt(show.date.split("-")[2]);
        const monthName = MONTHS[parsedMonth - 1];
        const monthDay = parsedDay;
        console.log(show);
        return (
          <div className="shows__single" key={index}>
            <div className="shows__single__details">
              <div className="shows__single__date">
                <p className="shows__single__date-month">{monthName}</p>
                <p className="shows__single__date-day">{monthDay}</p>
              </div>
              <div className="shows__single__event">
                <p className="shows__single__event-title">
                  {show.title[0].text}
                </p>
                <p className="shows__single__event-location">
                  {show.location[0].text}
                </p>
              </div>
            </div>
            <a
              href={show.tickets.url}
              target={show.tickets.target}
              className="shows__single-tickets"
            >
              GET TICKETS
            </a>
          </div>
        );
      });

      return (
        <>
          <div data-wio-id={this.state.doc.id} className="shows">
            <div className="container-overlay"></div>
            <div className="shows__container">
              <h1>Schedule</h1>
              <div className="shows__container__all">
                {shows}
                <div className="shows__social">
                  <div id="insta" className="instagram">
                    <span className="descriptions">
                      <p>Instagram</p>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    } else if (this.state.ComingSoon) {
      return <ComingSoon />;
    }
    return <h1>Loading</h1>;
  }
}
