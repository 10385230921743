import React from "react";
export default class Contact extends React.Component {
  render() {
    return (
      <>
        <div className="contact">
          <div className="container-overlay" />
          <div className="container">
            <h1>Get in touch</h1>
            <form name="contact" method="post">
              <input type="hidden" name="form-name" value="contact" />
              <div className="form-item inline">
                <input type="text" name="name" placeholder="Name (required)" />
              </div>
              <div className="form-item inline inline-right">
                <input
                  type="email"
                  name="email"
                  placeholder="Email (required)"
                />
              </div>
              <div className="form-item">
                <textarea name="message" placeholder="Message" />
              </div>
              <div className="form-item">
                <button type="submit">Submit</button>
              </div>
            </form>
          </div>
        </div>
      </>
    );
  }
}
