import React from "react";
import ComingSoon from "./ComingSoon";

export default class Videos extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      doc: null,
      ComingSoon: false
    };
    if (props.prismicCtx) {
      this.fetchPage(props);
    }
  }

  componentDidUpdate(prevProps) {
    this.props.prismicCtx.toolbar();
    if (!prevProps.prismicCtx) {
      this.fetchPage(this.props);
    }
  }

  fetchPage(props) {
    if (props.prismicCtx) {
      return props.prismicCtx.api.getSingle("videos", {}, (err, doc) => {
        if (doc) {
          this.setState({ doc });
        } else {
          this.setState({ ComingSoon: !doc });
        }
      });
    }
    return null;
  }

  render() {
    if (this.state.doc) {
      const videos = this.state.doc.data.videos.map(function(video, index) {
        return (
          <div className="videos__single" key={index}>
            <p className="videos__single-title">{video.title.text}</p>
            <div
              className="videos__single-element"
              dangerouslySetInnerHTML={{ __html: video.video[0].text }}
            />
          </div>
        );
      });

      return (
        <>
          <div data-wio-id={this.state.doc.id} className="videos">
            <div className="container-overlay"></div>
            <div className="videos__container">
              <h1>Videos</h1>
              <div className="videos__container__all">{videos}</div>
            </div>
          </div>
        </>
      );
    } else if (this.state.ComingSoon) {
      return <ComingSoon />;
    }
    return <h1>Loading</h1>;
  }
}
